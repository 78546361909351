import PropTypes from "prop-types";

// @mui/material
import { Box, Paper, Typography } from "@mui/material";

// sito components
import SitoImage from "sito-image";

// images
import noPhoto from "../../assets/images/no.jpg";
import clients from "../../assets/images/clients";

const BorderedCard = (props) => {
  const { sx, name, message, index } = props;

  return (
    <Paper
      elevation={3}
      sx={{
        gap: "20px",
        padding: "20px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "space-between",
        ...sx,
      }}
    >
      <Typography variant="body1" textAlign="justify">
        {message}
      </Typography>
      <Box
        sx={{
          gap: "20px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <SitoImage
          src={clients[index]}
          alt={name}
          sx={{
            width: "60px",
            height: "60px",
            borderRadius: "100%",
            objectFit: "cover",
          }}
        />
        <Typography variant="subtitle1" sx={{ margin: "20px 0" }}>
          {name}
        </Typography>
      </Box>
    </Paper>
  );
};

BorderedCard.defaultProps = {
  title: "Lorem ipsum",
  body: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ",
  icon: noPhoto,
};

BorderedCard.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  icon: PropTypes.string,
};

export default BorderedCard;
