// @mui/material
import { useTheme, Box, Typography } from "@mui/material";

// components
import InViewComponent from "../../../components/InViewComponent/InViewComponent";

// contexts
import { useLanguage } from "../../../context/LanguageProvider";

const About = () => {
  const theme = useTheme();

  const { languageState } = useLanguage();

  return (
    <Box
      id="about-us"
      sx={{
        gap: "20px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        background: theme.palette.background.paper,
        padding: { md: "8rem 10rem 5rem 10rem", sm: "5rem", xs: "30px" },
      }}
    >
      <InViewComponent delay="0.1s">
        <Typography
          variant="h3"
          sx={{
            textAlign: {
              md: "left",
              xs: "center",
            },
          }}
        >
          {languageState.texts.Home.AboutUs.Title}
        </Typography>
      </InViewComponent>

      <InViewComponent delay="0.3s" sx={{ width: { md: "50%", xs: "100%" } }}>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", textAlign: "center" }}
        >
          {languageState.texts.Home.AboutUs.Body.Subtitle}
        </Typography>
      </InViewComponent>
      <InViewComponent delay="0.5s" sx={{ width: { md: "50%", xs: "100%" } }}>
        <Typography sx={{ textAlign: "center" }}>
          {languageState.texts.Home.AboutUs.Body.Content}
        </Typography>
      </InViewComponent>
      <InViewComponent delay="0.7s" sx={{ width: { md: "50%", xs: "100%" } }}>
        <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
          {languageState.texts.Home.AboutUs.Body.Subtitle}
        </Typography>
      </InViewComponent>
    </Box>
  );
};

export default About;
