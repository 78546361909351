/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// @mui/material
import { ThemeProvider } from "@mui/material/styles";

// @mui components
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box, CssBaseline } from "@mui/material";

// sito components
import NotificationContext from "sito-mui-notification";

// themes
import dark from "./assets/theme/dark";
import light from "./assets/theme/light";

// contexts
import { useMode } from "./context/ModeProvider";

// layouts
import Main from "./layouts/Main";

// views
import Home from "./views/Home/Home";
import NotFound from "./views/NotFound/NotFound";

const App = () => {
  const { modeState } = useMode();

  useEffect(() => {
    document.body.style.overflowX = "hidden";
    document.body.style.transition = "all 200ms ease";
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={modeState.mode === "light" ? light : dark}>
        <CssBaseline />
        <NotificationContext>
          <Box>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
              <Routes>
                <Route path="/" element={<Main />}>
                  <Route index element={<Home />} />
                </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>
          </Box>
        </NotificationContext>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
