// @mui/material
import { Box, Typography } from "@mui/material";

// components
import BorderedCard from "../../../components/BorderedCard/BorderedCard";
import InViewComponent from "../../../components/InViewComponent/InViewComponent";

// contexts
import { useLanguage } from "../../../context/LanguageProvider";

const Reviews = () => {
  const { languageState } = useLanguage();

  return (
    <Box
      id="reviews"
      sx={{
        gap: "20px",
        display: "flex",
        minHeight: "100vh",
        flexDirection: "column",
        justifyContent: "center",
        padding: {
          md: "10rem",
          sm: "5rem",
          xs: "30px",
        },
      }}
    >
      <InViewComponent delay="0.1s">
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          {languageState.texts.Home.Reviews.Title}
        </Typography>
      </InViewComponent>
      <Box
        sx={{
          gap: "20px",
          display: "flex",
          marginTop: "20px",
          flexDirection: { md: "row", xs: "column" },
        }}
      >
        {languageState.texts.Home.Reviews.Clients.map(
          ({ name, content }, i) => (
            <InViewComponent delay="0.3s">
              <BorderedCard
                index={i}
                name={name}
                sx={{ flex: 1 }}
                message={content}
              />
            </InViewComponent>
          )
        )}
      </Box>
    </Box>
  );
};

export default Reviews;
