// sito components
import { useTheme, Box, Link, Typography } from "@mui/material";

// @mui/icons-material
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

// contexts
import { useLanguage } from "../../context/LanguageProvider";

const Footer = () => {
  const theme = useTheme();

  const { languageState } = useLanguage();

  return (
    <Box
      justifyContent="space-between"
      sx={{
        display: "flex",
        alignItems: "center",
        padding: { xl: "20px 5rem", lg: "20px 4rem", xs: "20px" },
      }}
    >
      <Box sx={{ gap: "10px", display: "flex", flexDirection: "column" }}>
        <Typography>
          {languageState.texts.Footer.Company} ©{new Date().getFullYear()}
        </Typography>

        <Box sx={{ gap: "10px", display: "flex" }}>
          <Link
            variant="caption"
            href={`tel:${languageState.texts.Footer.Phone}`}
            sx={{ gap: "10px", display: "flex", alignItems: "center" }}
          >
            <LocalPhoneIcon sx={{ fontSize: "20px" }} />
            {languageState.texts.Footer.Phone}
          </Link>
          <Link
            variant="caption"
            href={`mailto:${languageState.texts.Footer.Email}`}
            sx={{
              gap: "10px",
              display: "flex",
              alignItems: "center",
              color: theme.palette.alterSecondary.main,
            }}
          >
            <EmailIcon sx={{ fontSize: "20px" }} />
            {languageState.texts.Footer.Email}
          </Link>
          <p>{languageState.texts.Footer.Address}</p>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
