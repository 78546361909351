import { Link } from "react-router-dom";

// @emotion/css
import { css } from "@emotion/css";

// @mui/material
import { useTheme, Box, Typography, Button } from "@mui/material";

// sito components
import SitoImage from "sito-image";

// contexts
import { useLanguage } from "../../../context/LanguageProvider";

// components
import InViewComponent from "../../../components/InViewComponent/InViewComponent";

// images
import hero from "../../../assets/images/hero.jpg";

const Hero = () => {
  const theme = useTheme();

  const { languageState } = useLanguage();

  return (
    <Box
      id="home"
      sx={{
        display: "flex",
        minHeight: "100vh",
        position: "relative",
        justifyContent: "center",
        flexDirection: "row-reverse",
        backgroundCover: "cover",
        background: {
          md: theme.palette.background.paper,
          xs: `url('${hero}')`,
        },
      }}
    >
      <Box
        sx={{
          zIndex: 1,
          width: "100%",
          height: "100%",
          position: "absolute",
          display: { md: "none", xs: "inherit" },
          background: `${theme.palette.secondary.dark}dd`,
        }}
      ></Box>
      <Box
        sx={{
          height: "100vh",
          position: "relative",
          width: { lg: "50%", md: "75%", xs: 0, overflow: "hidden" },
        }}
      >
        <Box
          sx={{
            zIndex: 1,
            width: "100%",
            height: "100%",
            position: "absolute",
            background: `${theme.palette.secondary.dark}77`,
          }}
        ></Box>
        <SitoImage
          src={hero}
          alt="hero-truck"
          sx={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Box>
      <Box
        sx={{
          zIndex: 2,
          gap: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: { lg: "50%", md: "75%", xs: "100%" },
          alignItems: { md: "flex-start", xs: "center" },
          padding: { md: "0 5rem", sm: "0 5rem", xs: "20px" },
        }}
      >
        <InViewComponent delay="0.1s">
          <Typography
            variant="h2"
            sx={{
              color: theme.palette.alterSecondary.main,
              textAlign: {
                md: "left",
                xs: "center",
              },
            }}
          >
            {languageState.texts.Home.Hero.Title}
          </Typography>
        </InViewComponent>
        <InViewComponent delay="0.3s">
          <Typography
            variant="body1"
            sx={{
              textAlign: { md: "left", xs: "center" },
              color: theme.palette.alterSecondary.main,
            }}
          >
            {languageState.texts.Home.Hero.Body}
          </Typography>
        </InViewComponent>
        <InViewComponent delay="0.5s">
          <Link
            to="#contact-us"
            className={css({ textDecoration: "none", color: "inherit" })}
          >
            <Button
              color="alterSecondary"
              variant="contained"
              sx={{
                gap: "5px",
                fontSize: "15px",
                width: "155px",
                borderRadius: "30px",
              }}
            >
              {languageState.texts.Home.Hero.CatchToAction}
            </Button>
          </Link>
        </InViewComponent>
      </Box>
    </Box>
  );
};

export default Hero;
