import { Link } from "react-router-dom";

import PropTypes from "prop-types";

// @emotion/css
import { css } from "@emotion/css";

// sito components
import SitoImage from "sito-image";

// @mui/material
import { Box, Button, Paper, Typography } from "@mui/material";

// contexts
import { useLanguage } from "../../context/LanguageProvider";

// images
import noPhoto from "../../assets/images/no.jpg";

const ActionCard = (props) => {
  const { sx, src, title, body, action } = props;

  const { languageState } = useLanguage();

  return (
    <Paper
      elevation={3}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        ...sx,
      }}
    >
      <Box sx={{ width: "100%", height: "200px" }}>
        <SitoImage
          src={src}
          alt="title"
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "4px 4px 0 0",
          }}
        />
      </Box>
      <Box
        sx={{
          height: "100%",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="subtitle1">{title}</Typography>
        <Typography sx={{ textAlign: "justify" }} variant="body1">
          {body}
        </Typography>
        <Link
          to={action}
          className={css({ textDecoration: "none", alignSelf: "flex-end" })}
        >
          <Button variant="contained">
            {languageState.texts.Buttons.ReadMore}
          </Button>
        </Link>
      </Box>
    </Paper>
  );
};

ActionCard.defaultProps = {
  title: "Lorem ipsum",
  body: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ",
  src: noPhoto,
  action: "/",
};

ActionCard.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  src: PropTypes.string,
  action: PropTypes.string,
};

export default ActionCard;
